import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  cursor: zoom-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1rem;
  }
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  max-width: 90vw;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: default;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 4px;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-height: 60vh;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gradient.dark};
  border: 2px solid ${({ theme }) => theme.colors.accent.gold};
  color: ${({ theme }) => theme.colors.accent.gold};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1001;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.accent.gold};
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 32px;
    height: 32px;
    font-size: 1.25rem;
  }
`;

const ImageInfo = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0.5rem;
  margin-top: auto;

  h4 {
    color: ${({ theme }) => theme.colors.accent.gold};
    margin-bottom: 0.5rem;
    font-size: 1.1rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 1rem;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 0.9rem;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 0.8rem;
    }
  }
`;

const ImageViewer = ({ image, onClose }) => {
  const handleClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      <Overlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClick}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ type: "spring", damping: 15 }}
        >
          <StyledImage src={image.src} alt={image.alt || image.title} />
          {(image.title || image.description) && (
            <ImageInfo>
              {image.title && <h4>{image.title}</h4>}
              {image.description && <p>{image.description}</p>}
            </ImageInfo>
          )}
        </ImageContainer>
        <CloseButton onClick={onClose} aria-label="Close image viewer">×</CloseButton>
      </Overlay>
    </AnimatePresence>
  );
};

export default ImageViewer; 