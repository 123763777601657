import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledSection = styled(motion.section)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) => theme.spacing.section} 0;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gradient.dark};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.section} 1rem;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.gradient.primary};
  opacity: 0.7;
  z-index: 1;
`;

const FloatingParticles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
`;

const Particle = styled(motion.div)`
  position: absolute;
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.accent.gold};
  border-radius: 50%;
  opacity: 0.3;
`;

const Content = styled(motion.div)`
  max-width: ${({ theme }) => theme.spacing.contentWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.base};
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

const LogoWrapper = styled(motion.div)`
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
  
  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      max-width: 300px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      max-width: 250px;
    }
  }
`;

const Description = styled(motion.p)`
  font-size: ${({ theme }) => theme.typography.sizes.body};
  max-width: 800px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 1.8;
  letter-spacing: 0.5px;
  position: relative;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gradient.primary};
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 0.9rem;
    padding: 1.5rem;
    max-width: 100%;
    margin: 0 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 0.85rem;
    padding: 1.25rem;
    line-height: 1.6;
  }
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 40%;
  transform: translateX(-50%);
  z-index: 2;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.accent.gold};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 2px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  width: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: 1.5rem;
    left: 30%;
    font-size: 0.75rem;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    margin-top: 0.5rem;
    stroke: ${({ theme }) => theme.colors.accent.gold};
    width: 24px;
    height: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      width: 20px;
      height: 20px;
    }
  }
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 3rem;
    margin-bottom: 6rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 2rem;
    margin-bottom: 5rem;
  }
`;

const Introduction = () => {
  const generateParticles = (count) => {
    return Array.from({ length: count }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      delay: Math.random() * 5
    }));
  };

  const particles = generateParticles(20);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    }
  };

  const logoVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100
      }
    }
  };

  const descriptionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.5
      }
    }
  };

  const scrollToNextSection = () => {
    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <StyledSection
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <BackgroundOverlay />
      <FloatingParticles>
        {particles.map((particle) => (
          <Particle
            key={particle.id}
            style={{ left: `${particle.x}%`, top: `${particle.y}%` }}
            animate={{
              y: [0, -30, 0],
              opacity: [0.2, 0.8, 0.2],
              transition: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                delay: particle.delay
              }
            }}
          />
        ))}
      </FloatingParticles>
      <Content>
        <ContentWrapper>
          <LogoWrapper variants={logoVariants}>
            <img src="/logo.png" alt="Eternal King" />
          </LogoWrapper>
          <Description variants={descriptionVariants}>
            A Metroidvania adventure where health management is crucial to avoid a punishing death mechanic that can alter the game's outcome. Explore the mystery surrounding the absent king and his inescapable realm while avoiding the threat of a flesh infestation spreading throughout the environment.
          </Description>
        </ContentWrapper>
        <ScrollIndicator 
          onClick={scrollToNextSection}
          animate={{ 
            y: [0, 10, 0],
            opacity: [1, 0.5, 1]
          }}
          transition={{ 
            repeat: Infinity, 
            duration: 2,
            ease: "easeInOut"
          }}
        >
          Scroll to explore
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path d="M7 13l5 5 5-5M7 6l5 5 5-5" />
          </svg>
        </ScrollIndicator>
      </Content>
    </StyledSection>
  );
};

export default Introduction;