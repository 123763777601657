import React from 'react';
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    
    // Log to error tracking service
    if (process.env.NODE_ENV === 'production') {
      // Example using a service like Sentry
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    } else {
      console.error('Error:', error);
      console.error('Error Info:', errorInfo);
    }
    
    // Show user-friendly error UI
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { FallbackComponent } = this.props; // Accept FallbackComponent as prop

    if (hasError) {
      // If FallbackComponent is provided, render it with error details
      if (FallbackComponent) {
        return (
          <FallbackComponent
            error={error}
            errorInfo={errorInfo}
            resetErrorBoundary={() => this.setState({ hasError: false, error: null, errorInfo: null })}
          />
        );
      }
      // Otherwise, render a default message
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;