import React from 'react';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  margin-left: ${({ theme }) => theme.breakpoints.tablet} ? '0' : '250px';
  min-height: 100vh;
  transition: margin-left 0.3s ease;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 0;
    padding-top: 60px;
  }
`;

const Layout = ({ children }) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout; 