import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';

const Section = styled(motion.section)`
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing.section} 0;
  background: ${({ theme }) => theme.colors.gradient.dark};
  position: relative;
  overflow: hidden;
`;

const BackgroundDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 70% 30%, ${({ theme }) => theme.colors.accent.flesh}10 0%, transparent 60%);
  opacity: 0.1;
  pointer-events: none;
`;

const Container = styled.div`
  max-width: ${({ theme }) => theme.spacing.contentWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.base};
  position: relative;
  z-index: 1;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
  margin-bottom: 3rem;
  font-size: clamp(2rem, 4vw, ${({ theme }) => theme.typography.sizes.h2});
  text-shadow: ${({ theme }) => theme.shadows.glow};
  
  span {
    color: ${({ theme }) => theme.colors.accent.gold};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing.gridGap};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const Card = styled(motion.div)`
  background: ${({ theme }) => theme.colors.gradient.primary};
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
  }

  h3 {
    font-size: clamp(1.5rem, 3vw, ${({ theme }) => theme.typography.sizes.h3});
  }

  p {
    font-size: clamp(0.9rem, 2vw, 1rem);
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadows.glow};
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  margin: 1rem 0;

  li {
    margin-bottom: 1.2rem;
    padding-left: 2rem;
    position: relative;
    color: ${({ theme }) => theme.colors.text.secondary};
    transition: transform 0.3s ease;

    &::before {
      content: '✧';
      position: absolute;
      left: 0;
      color: ${({ theme }) => theme.colors.accent.gold};
      opacity: 0.8;
    }

    &:hover {
      transform: translateX(10px);
      color: ${({ theme }) => theme.colors.text.primary};
      
      &::before {
        opacity: 1;
      }
    }
  }
`;

const BudgetSection = styled(motion.div)`
  margin-top: 4rem;
  padding: 2.5rem;
  background: ${({ theme }) => theme.colors.gradient.dark};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
    margin-top: 3rem;
  }

  h3 {
    color: ${({ theme }) => theme.colors.accent.gold};
    margin-bottom: 1rem;
    text-align: center;
    font-size: ${({ theme }) => theme.typography.sizes.h3};
  }

  .total-budget {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
`;

const BudgetChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
`;

const BudgetBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .label {
    width: 120px;
    text-align: right;
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 0.9rem;
  }

  .bar-container {
    flex: 1;
    height: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }

  .bar {
    height: 100%;
    background: ${({ theme }) => theme.colors.accent.gold};
    width: ${({ percentage }) => percentage}%;
    transition: width 1s ease;
  }

  .percentage {
    width: 50px;
    color: ${({ theme }) => theme.colors.accent.gold};
    font-weight: bold;
    font-size: 0.9rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    .label {
      width: 100px;
      font-size: 0.8rem;
    }
    .percentage {
      font-size: 0.8rem;
    }
  }
`;

const budgetData = [
  { category: "Development", percentage: 35 },
  { category: "Art & Design", percentage: 30 },
  { category: "Marketing", percentage: 15 },
  { category: "QA & Testing", percentage: 10 },
  { category: "Misc", percentage: 5 },
  { category: "Legal", percentage: 5 }
];

const ProjectOverview = () => {
  const { ref, controls } = useScrollAnimation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100
      }
    }
  };

  return (
    <Section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <BackgroundDecoration />
      <Container>
        <Title>Project <span>Overview</span></Title>
        <Grid>
          <Card variants={itemVariants}>
            <h3>Game Concept</h3>
            <p>A unique Metroidvania adventure where every decision matters. The game features a punishing death mechanic that can dramatically alter the outcome of your journey.</p>
            <FeatureList>
              <li>Dynamic health management system</li>
              <li>Consequence-driven gameplay</li>
              <li>Rich, mysterious narrative</li>
              <li>Interconnected world design</li>
            </FeatureList>
          </Card>

          <Card variants={itemVariants}>
            <h3>Unique Features</h3>
            <FeatureList>
              <li>Flesh infestation progression system</li>
              <li>Environmental storytelling</li>
              <li>Adaptive difficulty mechanics</li>
              <li>Multiple narrative paths</li>
            </FeatureList>
          </Card>

          <Card variants={itemVariants}>
            <h3>Development Pipeline</h3>
            <FeatureList>
              <li>Pre-Production: 4-6 months</li>
              <li>Production (Alpha): 6-9 months</li>
              <li>Production (Beta): 12-18 months</li>
              <li>Post-Production: 2-3 months</li>
            </FeatureList>
          </Card>
        </Grid>

        
      </Container>
    </Section>
  );
};

export default ProjectOverview; 