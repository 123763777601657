import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';

const Section = styled(motion.section)`
  min-height: 60vh; // Shorter than other sections
  padding: ${({ theme }) => theme.spacing.section} 0;
  background: ${({ theme }) => theme.colors.gradient.dark};
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: ${({ theme }) => theme.spacing.contentWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.base};
  position: relative;
  z-index: 1;
  text-align: center;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 3rem;
  font-size: clamp(2rem, 4vw, ${({ theme }) => theme.typography.sizes.h2});
  text-shadow: ${({ theme }) => theme.shadows.glow};
  
  span {
    color: ${({ theme }) => theme.colors.accent.gold};
  }
`;

const Content = styled(motion.div)`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gradient.primary};
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 0.95rem;
      line-height: 1.6;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
    margin: 0 1rem;
  }
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.accent.gold};
  font-weight: ${({ theme }) => theme.typography.weight.medium};
`;

const ContactInfo = styled(motion.div)`
  margin-top: 3rem;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.accent.gold}30;

  h3 {
    color: ${({ theme }) => theme.colors.accent.gold};
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.accent.gold};
    text-decoration: none;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const Conclusion = () => {
  const { ref, controls } = useScrollAnimation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <Section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <Container>
        <Title>Project <span>Conclusion</span></Title>
        <Content variants={itemVariants}>
          <p>
            <Highlight>Eternal King</Highlight> represents not just a game, but the inaugural title for 
            <Highlight> Alsaad Production</Highlight> - Kuwait's pioneering independent game development studio. 
            This milestone marks the beginning of a new chapter in Kuwait's digital entertainment industry.
          </p>
          <p>
            With <Highlight>60% of pre-production</Highlight> completed, we're actively pursuing development kit 
            partnerships with major platforms including Xbox and PlayStation. Our vision extends beyond 
            just game development; we aim to establish ourselves as Kuwait's first officially incorporated 
            game development studio.
          </p>
          <p>
            Currently, we are in the process of securing investment and sponsorship opportunities to 
            support our ambitious goals. This funding will not only support the development of Eternal King 
            but also help establish a foundation for future game developers in Kuwait.
          </p>
          <p>
            As we move forward with the incorporation of Alsaad Production in Kuwait, we're setting 
            a precedent for future game development studios in the region, creating new opportunities 
            for local talent, and contributing to Kuwait's digital entertainment sector.
          </p>
          <ContactInfo variants={itemVariants}>
            <h3>Investment & Partnership Opportunities</h3>
            <p>For business inquiries: <a href="mailto:mohammad@alsaadproduction.com">mohammad@alsaadproduction.com</a></p>
          </ContactInfo>
        </Content>
      </Container>
    </Section>
  );
};

export default Conclusion; 