import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  padding: 2rem;
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid ${({ theme }) => theme.colors.accent.metallic};
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.p`
  color: ${({ theme }) => theme.colors.accent.metallic};
  font-family: ${({ theme }) => theme.typography.heading};
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const LoadingFallback = ({ children }) => {
  return (
    <LoadingWrapper>
      <LoadingSpinner />
      <LoadingText>{children}</LoadingText>
    </LoadingWrapper>
  );
};

export default LoadingFallback; 