import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LogoutButton from '../Auth/LogoutButton';

const NavContainer = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  background: ${({ theme }) => theme.colors.gradient.primary};
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 999;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  transform: translateX(${({ $isOpen }) => ($isOpen ? '0' : '-100%')});
  transition: transform 0.3s ${({ theme }) => theme.animation.easing.spring};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%; // Full width on mobile
    border-right: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    transform: translateX(0);
  }
`;

const Logo = styled(motion.div)`
  font-family: ${({ theme }) => theme.typography.heading};
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  img {
    width: 180px;
    height: auto;
    margin: 0 auto;
    transition: all 0.3s ease;
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
  flex: 1;
`;

const NavLink = styled(motion.a)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.sizes.body};
  padding: 0.75rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background: ${({ theme }) => theme.colors.accent.gold};
    transform: scaleY(0);
    transition: transform 0.3s ease;
  }

  &:hover, &.active {
    background: rgba(255, 255, 255, 0.05);
    color: ${({ theme }) => theme.colors.text.primary};
    
    &::before {
      transform: scaleY(1);
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.accent.gold};
    background: rgba(255, 255, 255, 0.1);
  }

  @media (hover: none) {
    &:active {
      opacity: 0.7;
    }
  }
`;

const MobileToggle = styled(motion.button)`
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gradient.primary};
  border: 2px solid ${({ theme }) => theme.colors.accent.gold};
  color: ${({ theme }) => theme.colors.accent.gold};
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.glow};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.accent.gold};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const MobileClose = styled(motion.button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.accent.gold};
  color: ${({ theme }) => theme.colors.accent.gold};
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const Footer = styled.div`
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
`;

const MainContent = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-top: 60px; // Space for mobile toggle button
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 250px; // Space for desktop navigation
  }
`;

const Navigation = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [isOpen, setIsOpen] = useState(false);

  const sections = useMemo(() => [
    { id: 'home', label: 'Home' },
    { id: 'about', label: 'About' },
    { id: 'overview', label: 'Overview' },
    { id: 'progress', label: 'Progress' },
    { id: 'showcase', label: 'Showcase' },
    { id: 'future', label: 'Future Plans' }
  ], []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;

      const sectionElements = sections.map(section => ({
        id: section.id,
        element: document.getElementById(section.id),
      }));

      for (const section of sectionElements) {
        if (!section.element) continue;
        
        const rect = section.element.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;
        const offsetBottom = offsetTop + rect.height;

        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  // Close menu when clicking outside on mobile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth <= 768 && isOpen && !event.target.closest('nav')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  // Close menu when resizing to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = window.innerWidth <= 768 ? 60 : 0;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
      setIsOpen(false);
    }
  };

  return (
    <>

      
      <NavContainer $isOpen={isOpen}>
        <MobileClose onClick={() => setIsOpen(false)}>✕</MobileClose>
        <Logo
          onClick={() => scrollToSection('home')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src="/logo.png" alt="Eternal King" />
        </Logo>

        <NavLinks>
          {sections.map(section => (
            <NavLink
              key={section.id}
              onClick={() => scrollToSection(section.id)}
              className={activeSection === section.id ? 'active' : ''}
              whileHover={{ x: 10 }}
              whileTap={{ x: 0 }}
              role="menuitem"
              aria-current={activeSection === section.id ? 'page' : undefined}
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && scrollToSection(section.id)}
            >
              {section.label}
            </NavLink>
          ))}
        </NavLinks>

        <Footer>
          <LogoutButton />
        </Footer>
      </NavContainer>
    </>
  );
};

export default Navigation; 