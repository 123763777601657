import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AuthWrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gradient.dark};
`;

const AuthForm = styled(motion.form)`
  background: ${({ theme }) => theme.colors.gradient.primary};
  padding: 3rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 2rem;
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  text-shadow: ${({ theme }) => theme.shadows.glow};
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.accent.gold};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.typography.body};

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.glow};
  }
`;

const Button = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.gradient.accent};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.typography.heading};
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled(motion.p)`
  color: ${({ theme }) => theme.colors.accent.flesh};
  margin-top: 1rem;
  font-size: ${({ theme }) => theme.typography.sizes.small};
`;

const PasswordProtection = ({ onAuthenticate, children }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if previously authenticated
  useEffect(() => {
    const auth = localStorage.getItem('ek_auth');
    if (auth === 'true') {
      setIsAuthenticated(true);
      onAuthenticate(true);
    }
  }, [onAuthenticate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Get password from either Replit secrets or local env
    const storedPassword = process.env.REACT_APP_PASSWORD || process.env.REPLIT_REACT_APP_PASSWORD;
    
    // Add debug log (remove in production)
    console.log('Environment check:', {
      password: password,
      storedPassword: storedPassword,
      hasEnvVar: !!process.env.REACT_APP_PASSWORD,
      hasReplitVar: !!process.env.REPLIT_REACT_APP_PASSWORD
    });
  
    if (password === storedPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('ek_auth', 'true');
      onAuthenticate(true);
    } else {
      setError('Invalid password. Please try again.');
      setPassword('');
    }
  };

  if (isAuthenticated) {
    return children;
  }

  return (
    <AuthWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <AuthForm onSubmit={handleSubmit}>
        <Title>Eternal King</Title>
        <p>Development Progress Showcase</p>
        <Input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          type="submit"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Access Project
        </Button>
        {error && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {error}
          </ErrorMessage>
        )}
      </AuthForm>
    </AuthWrapper>
  );
};

export default PasswordProtection; 