import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Header = styled.header`
  background: ${({ theme }) => `linear-gradient(
    to right,
    ${theme.colors.gradient.dark},
    ${theme.colors.gradient.primary}
  )`};
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent.gold}20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 997;
  backdrop-filter: blur(10px);
  height: 32px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.35rem 0.75rem;
    gap: 0.5rem;
    justify-content: center;
    height: 28px;
  }
`;

const StatusGroup = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.accent.gold}15;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 0.35rem;
    padding: 0.15rem 0.5rem;
    border-radius: 15px;
  }
`;

const StatusItem = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 12px;
    background: ${({ theme }) => theme.colors.accent.gold}30;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 0.25rem;
  }
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.typography.weight.medium};
  font-size: 0.75rem;
  letter-spacing: 0.02em;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 0.65rem;
    letter-spacing: 0;
  }
`;

const DevelopmentHeader = () => {
  return (
    <Header>
      <StatusGroup
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <StatusItem>
          <Value>Pre-Production</Value>
        </StatusItem>
        
        <StatusItem>
          <Value>60%</Value>
        </StatusItem>
      </StatusGroup>

      <StatusGroup
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <StatusItem>
          <Value>4/6</Value>
        </StatusItem>
        
        <StatusItem>
          <Value>Nov 5</Value>
        </StatusItem>
      </StatusGroup>
    </Header>
  );
};

export default DevelopmentHeader;