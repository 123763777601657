import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Button = styled(motion.button)`
  padding: 0.5rem 1rem;
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.accent.gold};
  color: ${({ theme }) => theme.colors.accent.gold};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.typography.body};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.accent.gold};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const LogoutButton = () => {
  const handleLogout = () => {
    localStorage.removeItem('ek_auth');
    window.location.reload();
  };

  return (
    <Button
      onClick={handleLogout}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      Logout
    </Button>
  );
};

export default LogoutButton; 