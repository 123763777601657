import React, { Suspense, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import { theme } from './styles/theme';
import Layout from './components/Layout/Layout';
import Navigation from './components/Navigation/Navigation';
import ScrollProgress from './components/ScrollProgress/ScrollProgress';
import DevelopmentHeader from './components/Development/DevelopmentHeader';
import Introduction from './components/Introduction/Introduction';
import AboutMe from './components/AboutMe/AboutMe';
import ProjectOverview from './components/ProjectOverview/ProjectOverview';
import ProgressTimeline from './components/ProgressTimeline/ProgressTimeline';
import LoadingFallback from './components/LoadingFallback/LoadingFallback';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import PasswordProtection from './components/Auth/PasswordProtection';
import ErrorFallback from './components/ErrorBoundary/ErrorFallback';
import styled from 'styled-components';
import Conclusion from './components/Conclusion/Conclusion';

// Lazy loaded components
const ArtisticShowcase = React.lazy(() =>
  import('./components/ArtisticShowcase/ArtisticShowcase').catch((err) => {
    console.error('Failed to load ArtisticShowcase:', err);
    return { default: () => <ErrorFallback error={err} /> };
  })
);
const FuturePlans = React.lazy(() =>
  import('./components/FuturePlans/FuturePlans').catch((err) => {
    console.error('Failed to load FuturePlans:', err);
    return { default: () => <ErrorFallback error={err} /> };
  })
);

// Custom loading component
const SectionLoadingFallback = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gradient.dark};
  color: ${({ theme }) => theme.colors.accent.gold};
  font-size: 1.5rem;
`;

// Add preload hint function
const preloadComponent = (path) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'script';
  link.href = path;
  document.head.appendChild(link);
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Move useEffect inside the component
  useEffect(() => {
    const timer = setTimeout(() => {
      preloadComponent('/static/js/ArtisticShowcase.chunk.js');
      preloadComponent('/static/js/FuturePlans.chunk.js');
    }, 2000);
    
    return () => clearTimeout(timer);
  }, []);

  const handleAuthentication = (status) => {
    setIsAuthenticated(status);
  };

  const MainContent = () => (
    <>
      <DevelopmentHeader />
      <Navigation />
      <ScrollProgress />
      <Layout>
        <main>
          <Introduction id="home" />
          <AboutMe id="about" />
          <ProjectOverview id="overview" />
          <ProgressTimeline id="progress" />
          
          <Suspense fallback={<SectionLoadingFallback>Loading artistic showcase...</SectionLoadingFallback>}>
            <ArtisticShowcase />
          </Suspense>
          
          <Suspense fallback={<SectionLoadingFallback>Loading future plans...</SectionLoadingFallback>}>
            <FuturePlans />
          </Suspense>
          <Conclusion />
        </main>
      </Layout>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary>
        <PasswordProtection onAuthenticate={handleAuthentication}>
          <MainContent />
        </PasswordProtection>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;