// src/components/ErrorBoundary/ErrorFallback.js
import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  padding: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.accent.gold};
  background: ${({ theme }) => theme.colors.gradient.dark};
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorFallback = ({ error, errorInfo, resetErrorBoundary }) => {
  return (
    <ErrorContainer role="alert">
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
          {error && error.toString()}
          <br />
          {errorInfo && errorInfo.componentStack}
        </details>
        <button onClick={resetErrorBoundary}>Try Again</button>
      </div>
    </ErrorContainer>
  );
};

export default ErrorFallback;