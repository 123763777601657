import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';

const Section = styled(motion.section)`
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing.section} 0;
  background: ${({ theme }) => theme.colors.gradient.dark};
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: ${({ theme }) => theme.spacing.contentWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.base};
  position: relative;
  z-index: 1;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
  margin-bottom: 4rem;
  font-size: clamp(2rem, 4vw, ${({ theme }) => theme.typography.sizes.h2});
  text-shadow: ${({ theme }) => theme.shadows.glow};
  
  span {
    color: ${({ theme }) => theme.colors.accent.gold};
  }
`;

const Timeline = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 0;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: ${({ theme }) => theme.colors.accent.gold};
    opacity: 0.3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1rem 0;
    margin: 0 1rem;
    
    &::before {
      left: 20px;
      transform: none;
    }
  }
`;

const TimelineItem = styled.div`
  display: flex;
  justify-content: ${props => props.position === 'right' ? 'flex-end' : 'flex-start'};
  padding-left: ${props => props.position === 'right' ? '50%' : '0'};
  padding-right: ${props => props.position === 'right' ? '0' : '50%'};
  margin-bottom: 4rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme, $completed }) => 
      $completed ? theme.colors.accent.gold : theme.colors.primary};
    border: 3px solid ${({ theme }) => theme.colors.accent.gold};
    box-shadow: ${({ theme }) => theme.shadows.glow};
    z-index: 2;
    transition: all 0.3s ease;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 50px !important;
    padding-right: 0 !important;
    justify-content: flex-start !important;
    margin-bottom: 2rem;

    &::before {
      left: 20px;
      transform: none;
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: 1.5rem;
    
    &::before {
      width: 14px;
      height: 14px;
    }
  }
`;

const TimelineContent = styled(motion.div)`
  width: 90%;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gradient.primary};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    ${props => props.position === 'right' ? 'left: -15px;' : 'right: -15px;'}
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-${props => props.position === 'right' ? 'right' : 'left'}: 15px solid ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 30px);
    padding: 1.5rem;

    &::before {
      left: -15px;
      border-right: 15px solid ${({ theme }) => theme.colors.secondary};
      border-left: none;
      top: 15px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1.25rem;
    
    &::before {
      top: 12px;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid ${({ theme }) => theme.colors.secondary};
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadows.glow};
  }
`;

const Year = styled.div`
  font-family: ${({ theme }) => theme.typography.heading};
  color: ${({ theme }) => theme.colors.accent.gold};
  font-size: ${({ theme }) => theme.typography.sizes.h3};
  margin-bottom: 1rem;
  text-shadow: ${({ theme }) => theme.shadows.glow};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.25rem;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-top: 1.5rem;
  overflow: hidden;
  position: relative;

  &::after {
    content: '${props => props.progress}%';
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.accent.gold};
  }

  div {
    width: ${props => props.progress}%;
    height: 100%;
    background: ${({ theme }) => theme.colors.gradient.accent};
    border-radius: 4px;
    transition: width 1.5s ${({ theme }) => theme.animation.easing.spring};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 6px;
    margin-top: 1rem;

    &::after {
      font-size: 0.75rem;
      top: -18px;
    }
  }
`;

const milestones = [
  {
    year: '2022',
    title: 'Project Inception',
    description: 'Initial concept development and collaboration with artists for concept art. Basic game mechanics defined.',
    completed: true,
    progress: 100,
    position: 'left'
  },
  {
    year: '2023',
    title: 'Prototype Development',
    description: 'Partnership with Brazilian artists for prototypes and animations. Initial prototypes created and character animations developed.',
    completed: true,
    progress: 100,
    position: 'right'
  },
  {
    year: 'Mid 2024',
    title: 'Pre-production Phase',
    description: 'Animated trailer pre-production and completion of full playable prototype. with music and sound effects.',
    completed: true,
    progress: 100,
    position: 'left'
  },
  {
    year: 'Late 2024',
    title: 'Studio Formation',
    description: 'Incorporation of indie game studio with partners. Team expansion and development pipeline established.',
    completed: true,
    progress: 20,
    position: 'right'
  },
  {
    year: 'Current',
    title: 'Pre-Production',
    description: 'Detailed game design documentation, asset creation pipeline setup, and core mechanics refinement.',
    completed: false,
    progress: 60,
    position: 'left'
  }
];

const ProgressTimeline = () => {
  const { ref, controls } = useScrollAnimation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <Section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <Container>
        <Title>Development Timeline</Title>
        <Timeline>
          {milestones.map((milestone, index) => (
            <TimelineItem
              key={milestone.year}
              position={milestone.position}
              $completed={milestone.completed.toString()}
              variants={itemVariants}
            >
              <TimelineContent position={milestone.position}>
                <Year>{milestone.year}</Year>
                <h3>{milestone.title}</h3>
                <p>{milestone.description}</p>
                <ProgressBar progress={milestone.progress}>
                  <div />
                </ProgressBar>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
    </Section>
  );
};

export default ProgressTimeline; 