import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&family=Cormorant+Garamond:wght@400;500;600&family=Spectral:wght@300;400;500&family=Julius+Sans+One&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => theme.colors.primary};
    
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 15px;
    }
    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 14px;
    }
  }

  body {
    font-family: ${({ theme }) => theme.typography.fonts.body};
    line-height: ${({ theme }) => theme.typography.lineHeight.body};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text.primary};
    min-height: 100vh;
    overflow-x: hidden;
    letter-spacing: 0.02em;
  }

  /* Typography */
  h1, h2, h3 {
    font-family: ${({ theme }) => theme.typography.fonts.heading};
    margin-bottom: 1.5rem;
    line-height: ${({ theme }) => theme.typography.lineHeight.heading};
    letter-spacing: ${({ theme }) => theme.typography.letterSpacing.wide};

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: clamp(1.5rem, 5vw, 2rem);
      padding: 0 1rem;
    }
  }

  h4, h5, h6 {
    font-family: ${({ theme }) => theme.typography.fonts.subheading};
    margin-bottom: 1rem;
    line-height: ${({ theme }) => theme.typography.lineHeight.normal};
  }

  p {
    margin-bottom: 1.5rem;
    font-weight: ${({ theme }) => theme.typography.weight.regular};
  }

  /* Links & Buttons */
  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    font-family: inherit;
  }

  /* Section Styling */
  section {
    position: relative;
    background: ${({ theme }) => theme.colors.gradient.dark};
    padding: ${({ theme }) => theme.spacing.section} 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 4rem 0;
      min-height: auto;
      justify-content: flex-start;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      padding: 3rem 0;
    }
  }

  /* Utility Classes */
  .accent-text {
    color: ${({ theme }) => theme.colors.accent.gold};
    font-weight: ${({ theme }) => theme.typography.weight.medium};
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.primary};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.accent.gold};
    border-radius: 4px;
    
    &:hover {
      background: ${({ theme }) => theme.colors.accent.metallic};
    }
  }

  /* Selection */
  ::selection {
    background: ${({ theme }) => theme.colors.accent.gold};
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (hover: none) {
    /* Touch device specific styles */
    button:active,
    a:active {
      opacity: 0.7;
    }
  }

  /* Add touch device support */
  @media (hover: none) {
    .card:hover {
      transform: none !important;
    }
    
    .card:active {
      transform: scale(0.98);
    }
  }
`;

export default GlobalStyle;