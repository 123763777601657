const theme = {
  colors: {
    primary: '#0F0F1A',      // Darker blue-tinted background
    secondary: '#1A1A2E',    // Slightly lighter blue-dark
    tertiary: '#162447',     // Deep royal blue
    text: {
      primary: '#FFFFFF',    // Pure white for main text
      secondary: '#E0E0E0',  // Slightly dimmed white
      muted: '#9CA3AF',     // Muted gray for less important text
    },
    accent: {
      gold: '#B8860B',      // Changed to darker gold (DarkGoldenrod)
      metallic: '#996515',   // Darker brass gold
      flesh: '#FF6B6B',      // Vibrant red for flesh infestation
      blue: '#4169E1'        // Royal blue for UI elements
    },
    gradient: {
      primary: 'linear-gradient(135deg, #1A1A2E 0%, #162447 100%, #1A1A2E 100%)',
      accent: 'linear-gradient(135deg, #B8860B 0%, #996515 100%)', // Updated gradient
      dark: 'linear-gradient(180deg, rgba(15,15,26,0.95) 0%, rgba(15,15,26,1) 100%)',
      card: 'linear-gradient(145deg, rgba(26,26,46,0.4) 0%, rgba(22,36,71,0.4) 100%)',
      glow: 'radial-gradient(circle at center, rgba(184,134,11,0.15) 0%, transparent 70%)'
    },
    effects: {
      blur: 'rgba(15,15,26,0.7)',
      cardHover: 'rgba(184,134,11,0.1)'
    }
  },
  typography: {
    fonts: {
      heading: "'Cinzel Decorative', serif",
      body: "'Inter', sans-serif",
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      black: 900,
    },
    sizes: {
      h1: '3.5rem',
      h2: '2.5rem',
      h3: '2rem',
      h4: '1.5rem',
      body: '1rem',
      small: '0.875rem',
    },
    lineHeight: {
      heading: 1.2,
      body: 1.6,
    },
    letterSpacing: {
      tight: '-0.05em',
      normal: '0',
      wide: '0.05em',
      wider: '0.1em',
    },
  },
  spacing: {
    base: '8px',
    section: '100px',
    contentWidth: '1200px',
    gridGap: '32px'
  },
  animation: {
    fast: '150ms',
    standard: '300ms',
    entrance: '500ms',
    scroll: '800ms',
    easing: {
      easeOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      spring: 'cubic-bezier(0.155, 1.105, 0.295, 1.12)'
    }
  },
  shadows: {
    small: '0 2px 4px rgba(0,0,0,0.1)',
    medium: '0 4px 6px rgba(0,0,0,0.1)',
    large: '0 10px 15px rgba(0,0,0,0.1)',
    glow: '0 0 15px rgba(212,175,55,0.3)'
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1440px'
  }
};

// Change to named export
export { theme }; 