import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { fadeInUp, staggerContainer } from '../../constants/animations';
import ImageViewer from '../common/ImageViewer';

const StyledSection = styled(motion.section)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) => theme.spacing.section} 0;
  overflow: visible;
  background: ${({ theme }) => theme.colors.gradient.dark};
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 4rem 1rem;
    min-height: auto;
    overflow: visible;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.gradient.primary};
  opacity: 0.7;
  z-index: 1;
`;

const FloatingParticles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
`;

const Particle = styled(motion.div)`
  position: absolute;
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.accent.gold};
  border-radius: 50%;
  opacity: 0.3;
`;

const Content = styled(motion.div)`
  max-width: ${({ theme }) => theme.spacing.contentWidth};
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 0.5rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.gridGap};
  align-items: start;
  margin-bottom: 4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ProfileSection = styled(motion.div)`
  background: ${({ theme }) => theme.colors.gradient.dark};
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  font-size: clamp(1.75rem, 4vw, ${({ theme }) => theme.typography.sizes.h2});
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: 2rem;
  }
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.accent.gold};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const JourneyList = styled.ul`
  list-style: none;
  margin: 2rem 0;

  li {
    margin-bottom: 1.5rem;
    position: relative;
    padding-left: 2rem;
    transition: transform 0.3s ease;

    &::before {
      content: '✧';
      color: ${({ theme }) => theme.colors.accent.gold};
      position: absolute;
      left: 0;
      font-size: 1.2rem;
      opacity: 0.8;
    }

    &:hover {
      transform: translateX(10px);
      
      &::before {
        opacity: 1;
      }
    }
  }
`;

const VisionContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.gradient.dark};
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);

  h3 {
    color: ${({ theme }) => theme.colors.accent.gold};
    margin-bottom: 1.5rem;
    font-size: ${({ theme }) => theme.typography.sizes.h3};
  }

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
    margin-bottom: 1.5rem;
    line-height: 1.8;
    font-size: ${({ theme }) => theme.typography.sizes.body};
  }
`;

const AchievementsSection = styled(motion.div)`
  margin-top: 4rem;
`;

const AchievementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 1rem;
  }
`;

const AchievementCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.gradient.dark};
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`;

const MediaSection = styled(motion.div)`
  margin-top: 4rem;
  padding: 0 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }

  h3 {
    text-align: center;
    color: ${({ theme }) => theme.colors.accent.gold};
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
`;

const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const MediaCard = styled(motion.div)`
  position: relative;
  background: ${({ theme }) => theme.colors.gradient.dark};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  aspect-ratio: 16/9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    
    &.playstation-image {
      object-position: top;
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
    transform: translateY(0);
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
    
    .overlay {
      opacity: 1;
    }
  }
`;

const StatNumber = styled.div`
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  color: ${({ theme }) => theme.colors.accent.gold};
  margin-bottom: 0.5rem;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;

  img {
    max-width: 90%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
  }

  button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: ${({ theme }) => theme.colors.accent.gold};
    border: none;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    font-weight: bold;
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const StudioSection = styled(motion.div)`
  margin-top: 4rem;
  padding: 3.5rem 2rem;
  background: ${({ theme }) => theme.colors.gradient.dark};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      ${({ theme }) => theme.colors.accent.gold},
      transparent
    );
  }

  h3 {
    text-align: center;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.accent.gold};
    font-size: ${({ theme }) => theme.typography.sizes.h2};
    text-transform: uppercase;
    letter-spacing: ${({ theme }) => theme.typography.letterSpacing.wide};
    text-shadow: ${({ theme }) => theme.shadows.glow};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem 1rem;
    margin-top: 3rem;
  }
`;

const LogoContainer = styled(motion.div)`
  max-width: 400px;
  margin: 0 auto 2.5rem;
  background: rgba(255, 255, 255, 0.97);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid ${({ theme }) => theme.colors.accent.gold}30;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadows.glow};
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 300px;
    padding: 1.5rem;
    margin: 0 auto 2rem;
  }
`;

const StudioLogo = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;
  
  img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 0 auto;
    display: block;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0;
  }
`;

const TeamMember = styled(motion.div)`
  text-align: center;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.gradient.card};
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadows.glow};
  }

  h4 {
    color: ${({ theme }) => theme.colors.accent.gold};
    margin-bottom: 1rem;
    font-size: 1.25rem;
    text-shadow: ${({ theme }) => theme.shadows.glow};
    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 1.1rem;
    }
  }

  .role {
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ theme }) => theme.typography.weight.medium};
    margin-bottom: 1rem;
    font-size: 1rem;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 0.9rem;
    }
  }

  .description {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 0.9rem;
    line-height: 1.6;
    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      font-size: 0.85rem;
    }
  }
`;

const StatusBadge = styled.div`
  background: ${({ theme }) => theme.colors.accent.gold};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  display: inline-block;
  margin: 0 auto;
  font-size: 0.9rem;
  font-weight: ${({ theme }) => theme.typography.weight.medium};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: ${({ theme }) => theme.shadows.glow};
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateX(-50%) translateY(-2px);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
`;

// Update the team members data
const teamMembers = [
  {
    name: "Mohammad Alsaad",
    role: "CEO & Project Lead",
    description: "Leading the creative vision and overall direction of Eternal King."
  },
  {
    name: "Abdulaziz Almutawa",
    role: "Business PR",
    description: "Managing public relations and business partnerships."
  },
  {
    name: "Mohammad Arab",
    role: "Business Development & Technical Director",
    description: "Overseeing technical development and business growth strategies."
  }
];

const AboutMe = () => {
  const { ref, controls } = useScrollAnimation(0.1);

  const generateParticles = (count) => {
    return Array.from({ length: count }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      delay: Math.random() * 5
    }));
  };

  const particles = generateParticles(20);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const achievements = [
    {
      number: '14,000+',
      title: 'Total Trophies',
      description: 'Collected across PlayStation Network'
    },
    {
      number: '300+',
      title: 'Platinum Trophies',
      description: 'Highest completion rate achievements'
    },
    {
      number: '#1',
      title: 'World Ranking',
      description: 'Former world leader in PSN trophy collection'
    }
  ];

  const mediaFeatures = [
    {
      src: '/images/media/youtube-plate.jpg',
      alt: 'YouTube Recognition Plate',
      title: 'YouTube Recognition',
      description: 'Achievement milestone recognition from YouTube Gaming'
    },
    {
      src: '/images/media/playstation-interview.jpg',
      alt: 'PlayStation Magazine Interview',
      title: 'PlayStation Magazine Feature',
      description: 'Featured interview about trophy hunting journey and gaming passion'
    }
  ];

  const [selectedMedia, setSelectedMedia] = useState(null);

  return (
    <StyledSection
      id="about"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <BackgroundOverlay />
      <FloatingParticles>
        {particles.map((particle) => (
          <Particle
            key={particle.id}
            style={{ left: `${particle.x}%`, top: `${particle.y}%` }}
            animate={{
              y: [0, -30, 0],
              opacity: [0.2, 0.8, 0.2],
              transition: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                delay: particle.delay
              }
            }}
          />
        ))}
      </FloatingParticles>
      <Content>
        <Title>About <span>Me</span></Title>
        <Grid>
          <ProfileSection variants={itemVariants}>
            <h3>Mohammad Alsaad</h3>
            <p>Age: 30</p>
            <p>Role: <Highlight>CEO and Project Lead</Highlight></p>
          </ProfileSection>
          
          <JourneyList>
            {[
              'Passionate game developer with a vision spanning over a decade',
              'Educational background focused on game development and design',
              'Created a rich lore universe over 10 years of development',
              'Founded an indie game studio with two talented partners'
            ].map((item, index) => (
              <motion.li
                key={index}
                variants={itemVariants}
                whileHover={{ x: 10 }}
              >
                {item}
              </motion.li>
            ))}
          </JourneyList>
        </Grid>

        <VisionContent variants={itemVariants}>
          <h3>Vision & Goals</h3>
          <p>
            My journey in game development began with a simple dream: to create
            immersive experiences that push the boundaries of storytelling and
            gameplay mechanics.
          </p>
          <p>
            Through <Highlight>Eternal King</Highlight>, I aim to position Kuwait
            on the global video game industry map and inspire the next generation
            of Kuwaiti game developers and artists.
          </p>
          <p>
            The game represents not just a personal achievement, but a stepping
            stone towards establishing a thriving game development scene in the
            region.
          </p>
        </VisionContent>

        <AchievementsSection variants={itemVariants}>
          <h3>Gaming Achievements</h3>
          <AchievementGrid>
            {achievements.map((achievement, index) => (
              <AchievementCard
                key={index}
                variants={itemVariants}
                whileHover={{ y: -10 }}
              >
                <StatNumber>{achievement.number}</StatNumber>
                <h4>{achievement.title}</h4>
                <p>{achievement.description}</p>
              </AchievementCard>
            ))}
          </AchievementGrid>
        </AchievementsSection>

        <MediaSection variants={itemVariants}>
          <h3>Media Recognition</h3>
          <MediaGrid>
            {mediaFeatures.map((feature, index) => (
              <MediaCard
                key={index}
                variants={itemVariants}
                whileHover={{ y: -10 }}
                onClick={() => setSelectedMedia(feature)}
                role="button"
                tabIndex={0}
              >
                <img 
                  src={feature.src} 
                  alt={feature.alt}
                  loading="lazy"
                />
                <div className="overlay">
                  <h4>{feature.title}</h4>
                  <p>{feature.description}</p>
                </div>
              </MediaCard>
            ))}
          </MediaGrid>
        </MediaSection>

        {selectedMedia && (
          <ImageViewer 
            image={selectedMedia} 
            onClose={() => setSelectedMedia(null)}
          />
        )}

        <StudioSection variants={itemVariants}>
          <h3>Studio Formation</h3>
          <LogoContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <StudioLogo>
              <img src="/studio-logo.png" alt="Alsaad Production Logo" />
            </StudioLogo>
          </LogoContainer>
          <StatusBadge>In Progress - Incorporating in Kuwait</StatusBadge>
          <TeamGrid>
            {teamMembers.map((member, index) => (
              <TeamMember
                key={index}
                variants={itemVariants}
                whileHover={{
                  y: -5,
                  boxShadow: theme => theme.shadows.glow,
                  transition: { duration: 0.2 }
                }}
              >
                <h4>{member.name}</h4>
                <p className="role">{member.role}</p>
                <p className="description">{member.description}</p>
              </TeamMember>
            ))}
          </TeamGrid>
        </StudioSection>
      </Content>
    </StyledSection>
  );
};

export default AboutMe;